@keyframes slide {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.wrapper:nth-child(1) {
  animation-delay: 0s;
}
.wrapper:nth-child(2) {
  animation-delay: 8s;
}
.wrapper:nth-child(3) {
  animation-delay: 16s;
}
.wrapper:nth-child(4) {
  animation-delay: 24s;
}
.wrapper:nth-child(5) {
  animation-delay: 32s;
}
.wrapper:nth-child(6) {
  animation-delay: 40s;
}
.wrapper:nth-child(7) {
  animation-delay: 48s;
}

.wrapper {
  width: 100vw;
  height: 100vh;
  animation: slide 12s;
  opacity: 0;
  flex-shrink: 0;
  position: absolute;
  top: 0;
  right: 0;
  object-fit: cover;
}
