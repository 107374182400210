@keyframes slide {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.wrapper {
  width: 100vw;
  height: 100vh;
  animation: slide 31s;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.movie {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
